<template>
    <div>
        <div class="pic_title">照片</div>
        <div class="pic_list">
           <el-image class="img" :src="item.pictureUrl" v-for="(item,index) in value" :key="index" :preview-src-list="srcList"></el-image>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      handler(val) {
        this.srcList = val.map((item) => item.pictureUrl);
      },
    },
  },
  data() {
    return {
      srcList: [], // 预览的图片
    };
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
    .pic_title{
        width: 100%;
        font-size: 20px;
        color: #333;
        font-weight: bolder;
    }
    .pic_list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .img{
            width: 220px;
            height:120px;
            margin: 10px;
        }
    }

</style>
