import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from '../form/lookPic/index';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  name: 'inventory',
  data() {
    return {

    };
  },
  created() {
    this.getConfigList('page_list');
  },

  methods: {
    setColumn(col) {
      const rowData = col;
      if (col.field === 'pictureReqVoList') {
        rowData.type = 'html';
        rowData.formatter = ({ row }) => '<span style="color:#C34231; cursor:pointer;">查看</span>';
      }
      return col;
    },
    cellClick({ row, column }) {
      if (column.property === 'pictureReqVoList') {
        this.formName = 'Form';
        this.formConfig = {
          type: 'view',
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
  },
};
