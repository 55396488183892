var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "pic_title" }, [_vm._v("照片")]),
    _c(
      "div",
      { staticClass: "pic_list" },
      _vm._l(_vm.value, function (item, index) {
        return _c("el-image", {
          key: index,
          staticClass: "img",
          attrs: { src: item.pictureUrl, "preview-src-list": _vm.srcList },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }